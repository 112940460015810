
import styles from './ReportList.module.scss';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PROTECTED_ROUTES } from '../../constants/route';
import { useParams, Link } from 'react-router-dom';
import sessionStorageServices from '../../global/sessionStorageServices';
import APPCONSTANTS from '../../constants/appConstants';
import WarningIcon from '../../assets/images/warning.svg';
import toastCenter from '../../utils/toastCenter';
import Modal from '../../components/modal/ModalForm';
import Loader from '../../components/loader/Loader';
import { isMOHReportPending } from '../../services/reportAPI';
import { useSelector } from 'react-redux';
import { roleSelector, rolesSelector } from '../../store/user/selectors';
import { getIsUserEMRFacilityAdmin, isKHISLastDateExceeded } from './MOHReport';

interface IMatchParams {
  siteId: string;
  siteName: string;
}

const ReportList = (props: IMatchParams): React.ReactElement => {

  const [showAlertModal, setShowAlertModal] = useState({
    message: '',
    show: false
  });
  const [loading, setLoading] = useState(false);
  const { siteId, siteName } = useParams<IMatchParams>();
  const role: any = useSelector(roleSelector);

  const roles: any = useSelector(rolesSelector);

  const hasDuplicateProfileAccess = useCallback(() => {
    const allRoles = roles.map((role: any) => role.name);
    return APPCONSTANTS.DUPLICATE_PROFILE_ACCESS.some((role) => allRoles.includes(role));
  }, [roles]);

  useEffect(() => {
    toastCenter.dismissAllToast();
    const MOHStatusPayload = {
      key: getLastMonthKey().monthYear,
      report_type: 'MOH-740',
      facilityId: siteId
    };
    if (getIsUserEMRFacilityAdmin(role)) {
      IsMOHReportPushedToKHIS(MOHStatusPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const countryId = sessionStorageServices.getItem(APPCONSTANTS.REGION_ID);

  const reports = useMemo(() => [
    { name: 'Presc', label: 'Prescription Medication', route: PROTECTED_ROUTES.prescriptionReport, hasAccess: true },
    { name: 'ScreenedReport', label: 'Screened Patients', route: PROTECTED_ROUTES.screenedReport, hasAccess: true },
    { name: 'EnrolledPatientReport', label: 'Enrolled Patients', route: PROTECTED_ROUTES.enrolledPatientReport, hasAccess: true },
    { name: 'AssessmentReport', label: 'Assessment Report', route: PROTECTED_ROUTES.assessmentReport, hasAccess: true },
    { name: 'Daily', label: ' NCD-Daily Register', route: PROTECTED_ROUTES.dailyReport, hasAccess: true },
    {
      name: 'PatientDuplicateReport',
      label: 'Duplicate Profiles',
      route: PROTECTED_ROUTES.patientDuplicateReport,
      hasAccess: hasDuplicateProfileAccess()
    }
  ], [hasDuplicateProfileAccess]);

  const getLastMonthKey = () => {
    const now = new Date();
    const newdate = new Date(now.setMonth(now.getMonth() - 1));
    const key = {
      month: '',
      monthYear: ''
    };
    key.month = APPCONSTANTS.MONTH[newdate.getMonth()].name;
    key.monthYear = APPCONSTANTS.MONTH[newdate.getMonth()].label + new Date().getFullYear();
    return key;
  };

  const IsMOHReportPushedToKHIS = useCallback(
    async (MOHStatusPayload) => {
      try {
        setLoading(true);
        const { data } = await isMOHReportPending(MOHStatusPayload);
        setLoading(false);
        if ((data.message?.is_data_pushedto_KHIS === false) && !isKHISLastDateExceeded()) {
          setShowAlertModal({
            message: `This site has pending reports of ${getLastMonthKey().month} to be sent to KHIS`,
            show: true
          });
        }
      } catch (error: any) {
        setLoading(false);
        setShowAlertModal({ message: error.message, show: true });
      }
    },
    []
  );

  const alertModalRender = () => {
    return (
      <div className={styles.alertItems}>
        <img className={styles.alertModalIcon} src={WarningIcon} alt='Alert' />
        <br />
        <p className={styles.alertModalText}>{showAlertModal.message}</p>
        <br />
        <button className={styles.closeButton} onClick={() => {
          setShowAlertModal({
            message: '',
            show: false
          });
        }}>OK</button>
      </div>
    );
  };

  const parsedData = useMemo(
    () =>
      reports
        .filter(({ hasAccess }) => hasAccess)
        .map(({ label, route, name }) => ({
          title: label,
          detailRoute: route.replace(':siteId', siteId).replace(':siteName', siteName),
          _id: countryId,
          icon: require(`../../assets/images/${name}.svg`).default,
          name: sessionStorageServices.getItem(APPCONSTANTS.COUNTRY_NAME)
        })), [reports, siteId, siteName, countryId]);

  return (
    <>
      <div className={`position-relative ${styles.regionContainer}`}>
        <div className='row'>
          {loading && <Loader />}
          {parsedData.map((data) => (
            <div className='col-3 p-1 width-20' key={data.title}>
              <div className='cards'>
                <Link
                  to={data.detailRoute}
                >
                  <div className='row p-2'>
                    <img src={data.icon} alt={`${data.title}_logo`} width={60} height={70} />
                  </div>
                  <div className={`row ${styles.report_card_text} p-1`}>
                    <p>{data.title.toUpperCase()}</p>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        show={showAlertModal.show}
        isAlertModal={true}
        title=''
        size='modal-md'
        autoHeight={false}
        hasFooter={false}
        // tslint:disable-next-line:no-empty
        handleFormSubmit={() => { }}
        render={alertModalRender}
      />
    </>
  );
};

export default ReportList;
