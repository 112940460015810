import React from 'react';
import EditIcon from '../../assets/images/edit.svg';
import PlusIcon from '../../assets/images/plus.svg';
import DownloadIcon from '../../assets/images/download.svg';
import styles from './IconButton.module.scss';

/**
 * A compoent for button with icon
 * @param param
 * @returns React.ReactElement
 */
const IconButton = (
  { label, isEdit, handleClick, isDownload, isDisabled }:
  { label: string, isEdit?: boolean, handleClick: () => void; isDownload?: boolean; isDisabled?: boolean}
): React.ReactElement => {
  return (
    <button className={`btn primary-btn ${styles.iconButton}`} onClick={handleClick} disabled={isDisabled}>
      {isEdit && <img src={EditIcon} className={styles.btnImg} alt='edit-icon' /> }
      {!isEdit && !isDownload && <img src={PlusIcon} className={styles.btnImg} alt='plus-icon' />}
      {isDownload &&
        <img src={DownloadIcon} className={styles.btnImg} alt='download-icon' />
      }
      <span className={styles.btnLabel}>{label}</span>
    </button>
  );
};

export default IconButton;

