import crypto from 'crypto';

import APPCONSTANTS from '../constants/appConstants';

const getEncryptionKey = () => {
  return crypto.pbkdf2Sync(
    process.env.REACT_APP_CRYPTR_SECRET_KEY as string,
    APPCONSTANTS.ENCRYPTION.SALT,
    APPCONSTANTS.ENCRYPTION.ITERATION,
    APPCONSTANTS.ENCRYPTION.KEYLEN,
    APPCONSTANTS.ENCRYPTION.DIGEST
  );
};

export const decryptData = (password: string) => {
  const key = getEncryptionKey();
  const decipher = crypto.createDecipheriv(
    APPCONSTANTS.ENCRYPTION.ALGM,
    key,
    APPCONSTANTS.ENCRYPTION.IV
  );
  return decipher.update(password, 'base64', 'utf8');
};

export const encryptData = (value: string) => {
  const key = getEncryptionKey();
  const cipher = crypto.createCipheriv(
    APPCONSTANTS.ENCRYPTION.ALGM,
    key,
    APPCONSTANTS.ENCRYPTION.IV
  );
  return cipher.update(value, 'utf8', 'base64') + cipher.final('base64');
};

/**
 * Appends zero before given number if number of digitd is less that minimumIntegerDigits
 * @param num
 * @param minimumIntegerDigits
 * @returns {string}
 */
export const appendZeroBefore = (
  num: number,
  minimumIntegerDigits: number
): string =>
  (Number(num) || 0).toLocaleString('en-US', {
    minimumIntegerDigits,
    useGrouping: false
  });

/**
 * A utility function to stop the event from propogating up on DOM
 * @param e
 */
export const stopPropogation = (e: React.BaseSyntheticEvent) => {
  try {
    e.stopPropagation();
  } catch (error) {
    console.error(error);
  }
};

/**
 * A utility function to check difference of two arrays
 */
export const differenceOfArray = (ary1: any, ary2: any) =>
  ary1.filter((value: any) => !ary2.includes(value));

/**
 * A utility function to convert camelCase to Sentence Case
 */
export const getSentenceCase = (camelCase: string) => {
  const result = camelCase.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}