import axios from 'axios';
import { IMergeProfileRequestPayload } from '../store/duplicateProfiles/types';

export const fetchProfilesForReview = (data: {
  groupId: number;
  isUnsure: boolean;
}) =>
  axios({
    method: 'POST',
    url: 'cfr-service/duplicate-profile/review-profile',
    data,
  });

export const fetchProfilesForEditForm = (data: {
  patientTrackIds: number[];
}) =>
  axios({
    method: 'POST',
    url: 'cfr-service/duplicate-profile/preview-details',
    data,
  });

export const updateProfileReviewStatus = (data: {
  groupId: number;
  isUnsure: boolean;
  mainPatientTrackId: number;
  suspectedPatientTrackId: number;
  isSuspectedDuplicateUnsure: boolean;
  isSuspectedDuplicateDifferent: boolean;
}) => 
  axios({
    method: 'POST',
    url: '/cfr-service/duplicate-profile/update-status',
    data
  })

  export const mergeProfile = (data: Omit<IMergeProfileRequestPayload, 'facilureCb'>) => 
    axios({
      method: 'POST',
      url: '/cfr-service/duplicate-profile/merge',
      data
    })