import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';

const getScreenedList = (state: AppState) => state.report.screenedList;
const getEnrolledPatient = (state: AppState) => state.report.enrolledPatientList;
const getAssessmentList = (state: AppState) => state.report.assessmentList;
const getDuplicatePatient = (state: AppState) => state.report.duplicatePatientList;
const getLoading = (state: AppState) => state.report.loading;
const getTotal = (state: AppState) => state.report.total;

export const screenedListSelector = createSelector(getScreenedList, (screenedList) => screenedList);
export const enrolledPatientSelector = createSelector(getEnrolledPatient, (enrolledPatientList) => enrolledPatientList);
export const assessmentListSelector = createSelector(getAssessmentList, (assessmentList) => assessmentList);
export const duplicatePatientSelector = createSelector(getDuplicatePatient, (duplicatePatientList) => duplicatePatientList);
export const loadingSelector = createSelector(getLoading, (loading) => loading);
export const totalSelector = createSelector(getTotal, (total) => total);
