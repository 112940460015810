import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as profileReviewService from '../../services/duplicateProfilesAPI';
import {
  IFetchIProfilesForReviewStateRequest,
  IFetchProfilesForEditFormRequest,
  IMergeProfileRequest,
  IUpdateProfileReviewStatusRequest
} from './types';
import {
  fetchProfilesForReviewFailure,
  fetchProfilesForReviewSuccess,
  fetchProfilesForEditFormSuccess,
  fetchProfilesForEditFormFailure,
  updateProfileReviewStatusFailure,
  updateProfileReviewStatusSuccess,
  mergeProfileSuccess,
  mergeProfileFailure
} from './actions';
import {
  FETCH_PROFILES_FOR_REVIEW_REQUEST,
  FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_REQUEST,
  UPDATE_PROFILE_REVIEW_STATUS_REQUEST,
  MERGE_PROFILE_REQUEST
} from './actionTypes';
import APPCONSTANTS from '../../constants/appConstants';

/*
  Worker Saga: Fired on FETCH_PROFILES_FOR_REVIEW_REQUEST action
*/
export function* fetchProfilesForReview(
  action: IFetchIProfilesForReviewStateRequest
): SagaIterator {
  try {
    const {
      data: { entityList: profileReviewData = [], totalCount, limit },
    } = yield call(profileReviewService.fetchProfilesForReview, action.payload);
    const payload = { profileReviewData, totalCount, limit };
    yield put(fetchProfilesForReviewSuccess(payload));
    action.successCb(profileReviewData.length > 1 ? APPCONSTANTS.SUCCESS : APPCONSTANTS.ERROR);
  } catch (e) {
    if (e instanceof Error) {
      action.payload.failureCb?.(e);
      yield put(fetchProfilesForReviewFailure(e));
    }
  }
}

/*
  Worker Saga: Fired on FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_REQUEST action
*/
export function* fetchProfilesForEditPatientForm(
  action: IFetchProfilesForEditFormRequest
): SagaIterator {
  try {
    const {
      data: { entityList: profileEditFormData, totalCount, limit },
    } = yield call(profileReviewService.fetchProfilesForEditForm, action.payload);
    const payload = { profileEditFormData, totalCount, limit };
    yield put(fetchProfilesForEditFormSuccess(payload));
    action.successCb();
  } catch (e) {
    if (e instanceof Error) {
      action.payload.failureCb?.(e);
      yield put(fetchProfilesForEditFormFailure(e));
    }
  }
}

/*
  Worker Saga: Fired on UPDATE_PROFILE_REVIEW_STATUS_REQUEST action
*/
export function* updateProfileReviewStatus(
  action: IUpdateProfileReviewStatusRequest
): SagaIterator {
  try {
    yield call(profileReviewService.updateProfileReviewStatus, action.payload);
    yield put(updateProfileReviewStatusSuccess());
    action.successCb();
  } catch (e) {
    if (e instanceof Error) {
      action.payload.failureCb?.(e);
      yield put(updateProfileReviewStatusFailure(e));
    }
  }
}

/*
  Worker Saga: Fired on UPDATE_PROFILE_REVIEW_STATUS_REQUEST action
*/
export function* mergeProfile(
  action: IMergeProfileRequest
): SagaIterator {
  try {
    yield call(profileReviewService.mergeProfile, action.payload);
    yield put(mergeProfileSuccess());
    action.successCb();
  } catch (e) {
    if (e instanceof Error) {
      action.payload.failureCb?.(e);
      yield put(mergeProfileFailure(e));
    }
  }
}


/*
  Starts worker saga on latest dispatched specific action.
  Allows concurrent increments.
*/
function* profileReviewSaga() {
  yield all([
    takeLatest(FETCH_PROFILES_FOR_REVIEW_REQUEST, fetchProfilesForReview),
    takeLatest(FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_REQUEST, fetchProfilesForEditPatientForm),
    takeLatest(UPDATE_PROFILE_REVIEW_STATUS_REQUEST, updateProfileReviewStatus),
    takeLatest(MERGE_PROFILE_REQUEST, mergeProfile)
  ]);
}

export default profileReviewSaga;
