import * as PROFILE_REVIEW_TYPES from './actionTypes';
import {
  IFetchIProfilesForReviewStateRequest,
  IFetchProfilesForReviewSuccessPayload,
  IFetchIProfilesForReviewStateSuccess,
  IFetchIProfilesForReviewStateFailure,
  IFetchProfilesForEditFormRequest,
  IFetchProfilesForEditFormSuccess,
  IFetchProfilesForEditFormFailure,
  IFetchProfilesForEditFormSuccessPayload,
  IUpdateProfileReviewStatusRequest,
  IUpdateProfileReviewStatusFailure,
  IMergeProfileRequest,
  IUpdateProfileReviewStatusSuccess,
  IMergeProfileSuccess,
  IMergeProfileFailure
} from './types';

export const fetchProfilesForReviewRequest = ({
  payload,
  successCb
}: Omit<
  IFetchIProfilesForReviewStateRequest,
  'type'
>): IFetchIProfilesForReviewStateRequest => ({
  type: PROFILE_REVIEW_TYPES.FETCH_PROFILES_FOR_REVIEW_REQUEST,
  payload,
  successCb
});

export const fetchProfilesForReviewSuccess = (
  payload: IFetchProfilesForReviewSuccessPayload
): IFetchIProfilesForReviewStateSuccess => ({
  type: PROFILE_REVIEW_TYPES.FETCH_PROFILES_FOR_REVIEW_SUCCESS,
  payload,
});

export const fetchProfilesForReviewFailure = (
  error: Error
): IFetchIProfilesForReviewStateFailure => ({
  type: PROFILE_REVIEW_TYPES.FETCH_PROFILES_FOR_REVIEW_FAILURE,
  error,
});

export const fetchProfilesForEditFormRequest = ({
  payload,
  successCb
}: Omit<
  IFetchProfilesForEditFormRequest,
  'type'
>): IFetchProfilesForEditFormRequest => ({
  type: PROFILE_REVIEW_TYPES.FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_REQUEST,
  payload,
  successCb
});

export const fetchProfilesForEditFormSuccess = (
  payload: IFetchProfilesForEditFormSuccessPayload
): IFetchProfilesForEditFormSuccess => ({
  type: PROFILE_REVIEW_TYPES.FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_SUCCESS,
  payload,
});

export const fetchProfilesForEditFormFailure = (
  error: Error
): IFetchProfilesForEditFormFailure => ({
  type: PROFILE_REVIEW_TYPES.FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_FAILURE,
  error,
});

export const updateProfileReviewStatusRequest = ({
  payload,
  successCb
}:Omit<
  IUpdateProfileReviewStatusRequest,
  'type'
>): IUpdateProfileReviewStatusRequest => ({
  type: PROFILE_REVIEW_TYPES.UPDATE_PROFILE_REVIEW_STATUS_REQUEST,
  payload,
  successCb
})

export const updateProfileReviewStatusSuccess = (): IUpdateProfileReviewStatusSuccess => ({
  type: PROFILE_REVIEW_TYPES.UPDATE_PROFILE_REVIEW_STATUS_SUCCESS
})

export const updateProfileReviewStatusFailure = (
  error: Error
): IUpdateProfileReviewStatusFailure => ({
  type: PROFILE_REVIEW_TYPES.UPDATE_PROFILE_REVIEW_STATUS_FAILURE,
  error
})

export const mergeProfileRequest = ({
  payload,
  successCb
}: Omit<
  IMergeProfileRequest,
  'type'
>): IMergeProfileRequest => ({
  type: PROFILE_REVIEW_TYPES.MERGE_PROFILE_REQUEST,
  payload,
  successCb
})

export const mergeProfileSuccess = (): IMergeProfileSuccess => ({
  type: PROFILE_REVIEW_TYPES.MERGE_PROFILE_SUCCESS
})

export const mergeProfileFailure = (error: Error): IMergeProfileFailure => ({
  type: PROFILE_REVIEW_TYPES.MERGE_PROFILE_FAILURE,
  error
})