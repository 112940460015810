export const FETCH_PROFILES_FOR_REVIEW_REQUEST = 'FETCH_PROFILES_FOR_REVIEW_REQUEST';
export const FETCH_PROFILES_FOR_REVIEW_SUCCESS = 'FETCH_PROFILES_FOR_REVIEW_SUCCESS';
export const FETCH_PROFILES_FOR_REVIEW_FAILURE = 'FETCH_PROFILES_FOR_REVIEW_FAILURE';
export const FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_REQUEST = 'FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_REQUEST';
export const FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_SUCCESS = 'FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_SUCCESS';
export const FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_FAILURE = 'FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_FAILURE';
export const UPDATE_PROFILE_REVIEW_STATUS_REQUEST = 'UPDATE_PROFILE_REVIEW_STATUS_REQUEST';
export const UPDATE_PROFILE_REVIEW_STATUS_SUCCESS = 'UPDATE_PROFILE_REVIEW_STATUS_SUCCESS';
export const UPDATE_PROFILE_REVIEW_STATUS_FAILURE = 'UPDATE_PROFILE_REVIEW_STATUS_FAILURE';
export const MERGE_PROFILE_REQUEST = 'MERGE_PROFILE_REQUEST';
export const MERGE_PROFILE_SUCCESS = 'MERGE_PROFILE_SUCCESS';
export const MERGE_PROFILE_FAILURE = 'MERGE_PROFILE_FAILURE';