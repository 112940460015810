export const PUBLIC_ROUTES = {
  login: '/',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:token'
};

export const PROTECTED_ROUTES = {
  regionDashboard: '/region',
  site: '/site',
  siteByRegion: '/region/:regionId',
  reportList: '/site/:siteId/:siteName',
  MOHReport: '/site/:siteId/:siteName/MOH-report',
  permanentReport: '/site/:siteId/:siteName/permanent-report',
  dailyReport: '/site/:siteId/:siteName/daily-report',
  fileDownload: '/region/:regionId/fileDownload',
  screenedReport: '/site/:siteId/:siteName/screened-patient-report',
  enrolledPatientReport: '/site/:siteId/:siteName/enrolled-patient-report',
  prescriptionReport: '/site/:siteId/:siteName/prescription-medication',
  manageUserRoles: '/manage-user-roles',
  assessmentReport: '/site/:siteId/:siteName/assessment-report',
  home: '/home',
  quickSightDashboard: '/quick-sight/dashboard',
  patientDuplicateReport: '/site/:siteId/:siteName/duplicate-profiles',
  reviewDuplicates: '/site/:siteId/:siteName/duplicate-profiles/:groupId'
};
