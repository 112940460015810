import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getDashboardURL } from '../../services/regionAPI';
import APPCONSTANTS from '../../constants/appConstants';
import Loader from '../../components/loader/Loader';
import { emailSelector } from '../../store/user/selectors';
import toastCenter from '../../utils/toastCenter';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import './Dashboard.scss';


/**
 * Lists all the regions
 * Provides search feature
 * Provided the links to navigate to creation page of super admin and region
 * @returns {React.ReactElement}
 */
const Dashboard = (): React.ReactElement => {
  const containerDiv = useRef(null);
  const [hideIframe, setHideIframe] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quickSightURL, setQuickSightURL] = useState(null);
  const username = useSelector(emailSelector);
  const loadDashboard = useCallback((url: string) => {
    const options = {
      url: quickSightURL,
      container: containerDiv.current,
      iframeResizeOnSheetChange: false,
      printEnabled: true,
      footerPaddingEnabled: true,
      sheetTabsDisabled: false,
      undoRedoDisabled: false,
      resetDisabled: false,
      errorCallback: () => {
        setHideIframe(false);
      }
    };
    QuickSightEmbedding.embedDashboard(options);

  }, [quickSightURL]);

  useEffect(() => {
    getDashboardURL({ username }).then(res => {
      setQuickSightURL(res.data.entity.embedUrl);
    }).catch((err) => {
      setLoading(false);
      toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.DASHBOARS_FAIL_MSG);
    }).finally(() => setLoading(false));
  }, [username]);

  useEffect(() => {
    if (quickSightURL) {
      loadDashboard(quickSightURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quickSightURL]);


  return (
    <>
      {loading && <Loader />}
      <div className={`position-relative`}>
        {!hideIframe &&
          <div className='iframeContainer' ref={containerDiv} />
        }
      </div>
    </>
  );
};

export default Dashboard;
