import axios from 'axios';
import { IFetchSiteListRequest } from '../store/site/types';

export const fetchSiteList = (parms: IFetchSiteListRequest) =>
  axios({
    method: 'POST',
    url: 'cfr-service/region/site-list',
    data: {
      limit: parms.limit,
      skip: parms.skip,
      countryId: parms.regionId,
      ...(parms.search ? { searchTerm: parms.search } : {})
    }
  });
