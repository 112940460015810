import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';

const getProfileReviewData = (state: AppState) =>
  state.profileReviewData.profileReviewData;
const getLoading = (state: AppState) => state.profileReviewData.loading;
const getTotal = (state: AppState) => state.report.total;
const getProfileEditFormData = (state: AppState) =>
  state.profileReviewData.profileEditFormData;


export const profileReviewDataSelector = createSelector(
  getProfileReviewData,
  (profileReviewData) => profileReviewData
);
export const loadingSelector = createSelector(getLoading, (loading) => loading);
export const totalSelector = createSelector(getTotal, (total) => total);
export const profileEditFormDataSelector = createSelector(
  getProfileEditFormData,
  (profileEditFormData) => profileEditFormData
);
