import * as PROFILE_REVIEW_ACTION_TYPES from './actionTypes';

import { IProfileReviewState, ProfileReviewActions } from './types';

const initialState: IProfileReviewState = {
  profileReviewData: [],
  profileEditFormData: [],
  loading: false,
  error: null,
};

const profileReviewReducer = (
  state: IProfileReviewState = initialState,
  action: ProfileReviewActions
) => {
  switch (action.type) {
    case PROFILE_REVIEW_ACTION_TYPES.FETCH_PROFILES_FOR_REVIEW_REQUEST:
    case PROFILE_REVIEW_ACTION_TYPES.FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_REQUEST:
    case PROFILE_REVIEW_ACTION_TYPES.UPDATE_PROFILE_REVIEW_STATUS_REQUEST:
    case PROFILE_REVIEW_ACTION_TYPES.MERGE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_REVIEW_ACTION_TYPES.FETCH_PROFILES_FOR_REVIEW_FAILURE:
    case PROFILE_REVIEW_ACTION_TYPES.FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_FAILURE:
    case PROFILE_REVIEW_ACTION_TYPES.UPDATE_PROFILE_REVIEW_STATUS_FAILURE:
    case PROFILE_REVIEW_ACTION_TYPES.MERGE_PROFILE_SUCCESS:
    case PROFILE_REVIEW_ACTION_TYPES.MERGE_PROFILE_FAILURE:
    case PROFILE_REVIEW_ACTION_TYPES.UPDATE_PROFILE_REVIEW_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROFILE_REVIEW_ACTION_TYPES.FETCH_PROFILES_FOR_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.payload.totalCount || 0,
        profileReviewData: action.payload.profileReviewData || [],
      };
    case PROFILE_REVIEW_ACTION_TYPES.FETCH_PROFILES_FOR_EDIT_PATIENT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        total: action.payload.totalCount || 0,
        profileEditFormData: action.payload.profileEditFormData || [],
      };
    default:
      return state;
  }
};

export default profileReviewReducer;
