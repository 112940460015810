export const FETCH_SCREENED_PATIENT_LIST_REQUEST = 'FETCH_SCREENED_PATIENT_LIST_REQUEST';
export const FETCH_SCREENED_PATIENT_SUCCESS = 'FETCH_SCREENED_PATIENT_SUCCESS';
export const FETCH_SCREENED_PATIENT_FAILURE = 'FETCH_SCREENED_PATIENT_FAILURE';
export const FETCH_ENTROLLED_PATIENT_REQUEST = 'FETCH_ENTROLLED_PATIENT_REQUEST';
export const FETCH_ENTROLLED_PATIENT_SUCCESS = 'FETCH_ENTROLLED_PATIENT_SUCCESS';
export const FETCH_ENTROLLED_PATIENT_FAILURE = 'FETCH_ENTROLLED_PATIENT_FAILURE';
export const FETCH_ASSESSMENT_LIST_REQUEST = 'FETCH_ASSESSMENT_LIST_REQUEST';
export const FETCH_ASSESSMENT_LIST_SUCCESS = 'FETCH_ASSESSMENT_LIST_SUCCESS';
export const FETCH_ASSESSMENT_LIST_FAILURE = 'FETCH_ASSESSMENT_LIST_FAILURE';
export const FETCH_DUPLICATE_PATIENT_LIST_REQUEST = 'FETCH_DUPLICATE_PATIENT_LIST_REQUEST';
export const FETCH_DUPLICATE_PATIENT_LIST_SUCCESS = 'FETCH_DUPLICATE_PATIENT_LIST_SUCCESS';
export const FETCH_DUPLICATE_PATIENT_LIST_FAILURE = 'FETCH_DUPLICATE_PATIENT_LIST_FAILURE';
