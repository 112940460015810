export const FETCH_REGIONS_REQUEST = 'FETCH_REGIONS_REQUEST';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAILURE = 'FETCH_REGIONS_FAILURE';

export const FETCH_FILE_URL_REQUEST = 'FETCH_FILE_URL_REQUEST';
export const FETCH_FILE_URL_REQUEST_SUCCESS = 'FETCH_FILE_URL_REQUEST_SUCCESS';
export const FETCH_FILE_URL_REQUEST_FAIL = 'FETCH_FILE_URL_REQUEST_FAIL';

export const SET_REGION_DETAIL = 'SET_REGION_DETAIL';
export const CLEAR_REGION_DETAIL = 'CLEAR_REGION_DETAIL';
