import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import CustomDatePicker from '../../components/filter/CustomDatePicker';
import DetailCard from '../../components/detailCard/DetailCard';
import CustomTable from '../../components/customtable/CustomTable';
import Loader from '../../components/loader/Loader';
import Modal from '../../components/modal/ModalForm';
import SpreadsheetViewer from '../../components/spreadSheetViewer/SpreadSheetViewer';
import APPCONSTANTS from '../../constants/appConstants';
import { fetchPrescriptionList, fetchPrescriptionReport, fileDownload } from '../../services/reportAPI';
import toastCenter from '../../utils/toastCenter';
import { getFormattedDate } from '../../utils/reportUtil';
import sessionStorageServices from '../../global/sessionStorageServices';
import styles from './ReportList.module.scss';

interface IMatchParams {
  siteName: string;
}

interface IDatesParams {
  id?: number;
  year?: string;
  month?: string;
  label?: string;
}

interface IReportLists {
  month: string;
  year: string;
}

const PrescriptionReport = (props: IMatchParams): React.ReactElement => {
  const { siteName } = useParams<IMatchParams>();
  const startYearPickerRef = useRef<DatePicker>(null);
  const startMonthPickerRef = useRef<DatePicker>(null);
  const [modalParams, setModalReqParams] = useState({
    loading: false, data: {}
  });
  const [loading, setLoading] = useState(false);
  const [reportParams, setReportParams] = useState<IDatesParams[]>([]);
  const [startYear, setStartYear] = useState<null | Date>(null);
  const [startMonth, setStartMonth] = useState<null | Date>(null);
  let selectedYear: number;
  let selectedMonth: number;
  const siteTenantId = sessionStorageServices.getItem(APPCONSTANTS.SITE_TENANT_ID);

  useEffect(() => {
    getReportList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!startYear) {
      startMonthPickerRef.current?.props.onChange(null, undefined);
      setStartMonth(null);
    }
    if ((startYear && !startMonth) || (startYear && startMonth)) {
      startMonthPickerRef.current?.props.minDate?.setDate(startYear.getDate());
      startMonthPickerRef.current?.props.minDate?.setMonth(startYear.getMonth());
      startMonthPickerRef.current?.props.minDate?.setFullYear(startYear.getFullYear());
    }
    if (startMonth) {
      startYearPickerRef.current?.props.maxDate?.setDate(startMonth.getDate());
      startYearPickerRef.current?.props.maxDate?.setMonth(startMonth.getMonth());
      startYearPickerRef.current?.props.maxDate?.setFullYear(startMonth.getFullYear());
    }
  }, [startYear, startMonth]);

  const getReportList = useCallback(async (reportYear?: number, reportMonth?: number) => {
    const datesList: IDatesParams[] = [];
    try {
      setLoading(true);
      const payLoad = await fetchPrescriptionList({
        tenantId: Number(siteTenantId),
        year: reportYear,
        month: reportMonth,
        limit: APPCONSTANTS.NUMBER.TWELVE
      });
      payLoad.data.entityList.map((res: IReportLists) => {
        return datesList.push({
          year: res.year,
          month: formatMonthName(res.month),
          label: res.month
        });
      });
      setLoading(false);
      setReportParams(datesList);
    } catch (error: any) {
      setReportParams(datesList);
      setLoading(false);
      toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND);
    }
  }, [siteTenantId]);

  const formatMonthName = (month: string) => {
    const result = APPCONSTANTS.MONTH.find(item => Object.entries(item).some(([key, value]) =>
      key === 'label' && value === month));
    return result?.name;
  };

  const getReportView = useCallback(async (report) => {
    const dates = getFormattedDate(report);
    try {
      setLoading(true);
      const { data: { entityList } } = await fetchPrescriptionReport({
        tenantId: Number(siteTenantId),
        fromDate: dates[0],
        toDate: dates[1]
      });
      setLoading(false);
      setModalReqParams({ loading: true, data: entityList });
    } catch (error: any) {
      setLoading(false);
      toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND_MONTH);
    }
  }, [siteTenantId]);

  const downloadFile = useCallback(async (report) => {
    const dates = getFormattedDate(report);
    try {
      setLoading(true);
      const { data: { entityList } } = await fetchPrescriptionReport({
        tenantId: siteTenantId,
        fromDate: dates[0],
        toDate: dates[1]
      });
      setLoading(false);
      fileDownload(siteName, '_Prescription_', report.year + '_' + report.month, entityList);
      toastCenter.success(APPCONSTANTS.SUCCESS, APPCONSTANTS.REPORT_DOWNLOAD_SUCCESS);
    } catch (error: any) {
      setLoading(false);
      toastCenter.error(APPCONSTANTS.ERROR, APPCONSTANTS.REPORT_NOT_FOUND_MONTH);
    }
  }, [siteTenantId, siteName]);

  const viewModalRender = () => {
    return <SpreadsheetViewer data={modalParams.data} isEdit={false} className={styles.spreadSheetContainer} />;
  };

  const handleCancelClick = () => {
    setModalReqParams({ loading: false, data: {} });
  };

  const onYearSelection = (date: Date) => {
    if (date) {
      setStartYear(date);
      selectedYear = date.getFullYear();
      if (startMonth) {
        selectedMonth = startMonth.getMonth() + 1;
        getReportList(selectedYear, selectedMonth);
      } else {
        getReportList(selectedYear);
      }
    } else {
      setStartYear(null);
      setStartMonth(null);
      getReportList();
    }
  };

  const onMonthSelection = (date: Date) => {
    if (date) {
      setStartMonth(date);
      selectedMonth = date.getMonth() + 1;
      if (startYear) {
        selectedYear = startYear.getFullYear();
        getReportList(selectedYear, selectedMonth);
      }
    } else {
      setStartMonth(null);
      if (startYear) {
        selectedYear = startYear.getFullYear();
        getReportList(selectedYear);
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className='row'>
        <div className='col-auto'>
          <div className={`${styles.sideMenu} ${styles.customSidemenu}`}>
            <div className={`card-header bg-transparent ${styles.header}`}>
              <span className={styles.headerLabel}>Filters</span>
            </div>
            <br />
            <div>
              <label>Year</label>
              <CustomDatePicker
                pickerRef={startYearPickerRef}
                dayReducer={1}
                onDateSelect={(date: Date) => onYearSelection(date)}
                showYearPicker={true}
              />
              <br /><br />
              <div className={startYear ? '' : `${styles.disable}`}>
                <label>Month</label>
                <CustomDatePicker
                  pickerRef={startMonthPickerRef}
                  onDateSelect={(date: Date) => onMonthSelection(date)}
                  showMonthYearPicker={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='col'>
          <DetailCard header='Prescription Medication'>
            <CustomTable
              rowData={reportParams}
              columnsDef={[
                {
                  id: 1,
                  name: 'year',
                  label: 'Year',
                  class: 'numeric'
                },
                {
                  id: 2,
                  name: 'month',
                  label: 'Month'
                }
              ]}
              isDelete={false}
              isEdit={false}
              isView={true}
              isDownload={true}
              onRowView={(report) => getReportView(report)}
              onRowDownload={(report) => downloadFile(report)}
            />
          </DetailCard>
          <Modal
            show={modalParams.loading}
            title='Prescription Medication View'
            size='modal-full'
            hasFooter={false}
            handleCancel={handleCancelClick}
            handleFormSubmit={handleCancelClick}
            render={viewModalRender}
          />
        </div>
      </div>
    </>
  );
};

export default PrescriptionReport;
