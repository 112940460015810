import React, { useCallback, useRef } from 'react';
import styles from './Checkbox.module.scss';

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

const Checkbox = ({ label, ...inputProps }: ICheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const onKeyPress = useCallback((e: React.KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.key === 'Enter') {
      checkboxRef.current?.click();
    }
  }, []);

  return (
    <label className='d-inline-flex align-items-center'>
      <div className={styles.checkboxWrapper}>
        <input
          type='checkbox'
          name={inputProps.name || label}
          {...inputProps}
          className={styles.checkbox}
          ref={checkboxRef}
          onKeyPress={onKeyPress}
        />
      </div>
      {label && <span className={styles.checkboxLabel}>{label}</span>}
    </label>
  );
};

export default Checkbox;
