import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';

import Loader from './components/loader/Loader';
import Header from './components/header/Header';
import { AppRoutes } from './routes';
import {
  getIsLoggedInSelector,
  getIsLoggingInSelector,
  getIsLoggingOutSelector
} from './store/user/selectors';
import './App.scss';

const App = () => {
  const loggingIn = useSelector(getIsLoggingInSelector);
  const loggedIn = useSelector(getIsLoggedInSelector);
  const loggingOut = useSelector(getIsLoggingOutSelector);
  return (
    <div className='app-container'>
      {loggedIn ? <Header /> : null}
      <div className={`app-body ${loggedIn ? 'logged-in' : ''}`}>
        {loggingIn || loggingOut ? <Loader /> : null}
        <AppRoutes />
      </div>
      <ToastContainer />
    </div>
  );
};

export default App;
