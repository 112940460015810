export const isEmpty = (value: string | undefined | null) =>
  value === undefined || value === null || value === '' || value.length === 0;

/**
 * To check whether the value is falsy or truthy.
 * @param {string} value
 * @returns {string}
 */
export function required(value: string) {
  if (value && typeof value === 'string') {
    value = value.trim();
  }
  if (isEmpty(value)) {
    return 'Please enter ';
  }
}

/**
 * To check whether email is valid or not
 * @param email email string
 */
export function validateEmail(email: string) {
  if (!email) {
    return '';
  }
  const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return regex.test(email) ? '' : 'Please enter a valid ';
}

export function validatePassword(password: string) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,64}$/;
  return regex.test(password) ? '' : 'Please enter a valid password';
}

/**
 * Validates the maxixmum length of characters to enter.
 * @param max Text to verify maximum length
 */
export function maxLength(max: number) {
  return (value: string) => {
    if (value && value.length > max) {
      return 'Please enter a valid';
    }
  };
}

/**
 * Composes all the validators into single function
 * @param validators
 * @returns
 */
export function composeValidators(...validators: any) {
  return (value: string, allValues: object) =>
    validators.reduce(
      (error: string, validator: any) => error || validator(value, allValues),
      undefined
    );
}

/**
 * Removes all characters except numerics in the given string
 * @param value
 * @returns {string}
 */
export function convertToNumber(value: string): string {
  if (value) {
    value = value.replace(/[^\d]/g, '');
    return value;
  } else {
    return value;
  }
}
