import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loader/Loader';
import APPCONSTANTS from '../../constants/appConstants';
import { fetchFileURL } from '../../store/region/actions';
import { getRegionsLoadingSelector } from '../../store/region/selectors';
import toastCenter from '../../utils/toastCenter';

import styles from './Region.module.scss';

const FileDownloader = (): React.ReactElement => {
  const dispatch = useDispatch();
  const loading = useSelector(getRegionsLoadingSelector);
  const [url, setURL] = useState('');
  const [downloadloading, setdownloadloading] = useState(false);

  useEffect(() => {
    dispatch(fetchFileURL((data: { url: string }) => {
      setURL(data.url);
    }, console.error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const downloadURLFinder = () => {
    if (url) {
      setdownloadloading(true);
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
      try {
        window.URL.revokeObjectURL(url);
      } catch (e) {
        toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.DOWNLOAD_FAIL_MSG);
      }
      setTimeout(() => {
        setdownloadloading(false);
      }, 1000);
    } else {
      toastCenter.error(APPCONSTANTS.OOPS, APPCONSTANTS.DOWNLOAD_NOT_READY);
    }
  };

  return (
    <>
    {(loading || downloadloading) && <Loader />}
      <div className={`position-relative ${styles.regionContainer}`}>
        <div className={styles.regionsList}>
          <div className='row'>
            <div className={`col-12 text-left mt-1 py-dot75 ${styles.noData}`}>
              <div className={`fw-bold highlight-text mb-1 ${styles.downloadHeading}`}>Kenya Country-Level Data</div>
              <div className={`${styles.downloadText}`}>
                {
                  `Click the download button below to access the complete
                  data extract for all programs operating in Kenya.
                  All of latest data can be accessed at anytime and
                  is provided in .csv format to enable data analysis.`
                }
              </div>
              <div className='text-center mt-1'>
                <button className='btn primary-btn' onClick={downloadURLFinder}>Download</button>
              </div>
            </div>
          </div>
          <div className={`${styles.downloadCopyright} mt-3 fw-bold`}>
            {
              `In accordance with our data security and privacy policies,
                in downloading the data, you agree to be responsible for the
                security of the data once locally stored on your device.
                Best practices including keeping local data retention to a minimum
                and ensuring all storage is password encrypted.
                Do not redistribute sensitive data to others,
                unless you are an authoritative source for and an authorized
                distributor of that data and the recipient is authorized to receive that data.`
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default FileDownloader;
