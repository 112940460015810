import * as USER_ROLES_ACTION_TYPES from './actionTypes';
import { IUserRolesState, UserRolesActions } from './types';

const initialState: IUserRolesState = {
  loading: false,
  userRolesListCount: 0,
  userRolesList: [],
  error: null,
  siteAdminSites: []
};

const userRolesReducer = (state: IUserRolesState = initialState, action: UserRolesActions) => {
  switch (action.type) {
    case USER_ROLES_ACTION_TYPES.FETCH_USER_ROLES_LIST_REQUEST:
    case USER_ROLES_ACTION_TYPES.CHANGE_ADMIN_USER_ROLE_REQUEST:
    case USER_ROLES_ACTION_TYPES.FETCH_SITE_ADMIN_SITES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case USER_ROLES_ACTION_TYPES.FETCH_USER_ROLES_LIST_FAILURE:
    case USER_ROLES_ACTION_TYPES.CHANGE_ADMIN_USER_ROLE_FAILURE:
    case USER_ROLES_ACTION_TYPES.FETCH_SITE_ADMIN_SITES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.error
      };
    case USER_ROLES_ACTION_TYPES.FETCH_USER_ROLES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        userRolesListCount: action?.payload.totalCount || 0,
        userRolesList: action?.payload.userRolesList || []
      };
    case USER_ROLES_ACTION_TYPES.CHANGE_ADMIN_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case USER_ROLES_ACTION_TYPES.FETCH_SITE_ADMIN_SITES_SUCCESS:
      return {
        ...state,
        siteAdminSites: action?.payload?.siteAdminSites,
        loading: false
      };
    default:
      return state;
  }
};

export default userRolesReducer;
